// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const VideoCover = ({ title, videoUrl, scrollable }) => (
    <div 
      className={
        scrollable ? "scroll-jacker__cassette scroll-jacker__cassette--active hero hero--page-home hero--video inuit-box inuit-box--flush" : 
        "hero hero--smaller hero--video hero--page-about-us inuit-box inuit-box--flush"
      }
    >
        <h2 className="hero__message hero__message--bigger hero__message--center">{title}</h2>
        <video className="hero__video hero__video--in-cassette" autoPlay loop muted>
            <source src={videoUrl} type="video/mp4" />
        </video>
        {
          scrollable ? (<button type="button" data-wall-slide-arrow className="hero__chevron-south inuit-btn" />) : (<></>)
        }
    </div>

)

VideoCover.propTypes = {
  videoUrl: PropTypes.string,
  title: PropTypes.string,
  scrollable: PropTypes.bool
}

VideoCover.defaultProps = {
  videoUrl: ``,
  title: ``,
  scrollable: true,
}

export default VideoCover

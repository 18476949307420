// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import VideoCover from "./video-cover"
import ImageCover from "./image-cover"

const LastRealisation = () => (
	<React.Fragment>

        { /* <section className="scroll-section"> */}
            <VideoCover
                title="Nos dernières réalisations"
                videoUrl="/video/test.mp4"
            />
        {/* </section> */}


        { /* <section className="scroll-section"> */}
            <ImageCover
                title="LA CAISSE ENREGISTREUSE SUR IPAD"
                content="Gérez votre business du bout des doigts !
                Cet outil vous permet de centraliser tous les aspects de votre business, des commandes en ligne à l’encaissement en passant par  la gestion des stocks, en un seul et unique système."
                classDiv="hero--precisionsystems"
                btn={{
                value: "En savoir plus",
                action: "/projets/precision-systems"
                }}
            />
        {/* </section> */}

        { /* <section className="scroll-section"> */}
            <ImageCover
                title="My SODEXO MAROC"
                content="Grâce à son module de géolocalisation, l’application mobile de Sodexo vous permet de localiser rapidement sur carte les commerces autour de vous afin de connaitre les lieux d’utilisation de vos Mida et Cado Pass."
                classDiv="hero--sodexo"
                btn={{
                    value: "En savoir plus",
                    action: "/projets/sodexo"
                }}
            />
        {/* </section> */}
        { /* <section className="scroll-section"> */}
            <ImageCover
                title="DHJ"
                content="L 'application officielle du Difaa el Hassani el Jadidii (DHJ) vous permet de suivre toute l'actualité de l 'équipe première du club."
                image = "/img/hero/DHJ-site.jpg"
                classDiv = "hero--dhj-site"
                btn={{
                    value: "En savoir plus",
                    action: "/projets/dhj"
                }}
            />
        {/* </section> */}
        { /* <section className="scroll-section"> */}
            <ImageCover
                title="Minyadi"
                content="تقدم لكم شركة من يدي أسرار الجمال و العناية بالبشرة و الجسم و الشعر بطرق تقليدية و مكونات طبيعية مغربية ، من خلال مجموعة متكاملة كلها مصنوعة يدويا."
                classDiv="hero--minyadi "
                btn={{ 
                    value: "En savoir plus",
                    action: "/projets/minyadi"
                }}
            />
        {/* </section> */}

        { /* <section className="scroll-section"> */}
            <ImageCover
                title="SODEXO MAROC"
                content="Plus qu’ un site institutionnel, Sodexo.ma a été pensé de façon a ce qu’ il soit orienté résultat (conversions)."
                image = "/img/hero/sodexo-site.jpg"
                classDiv = "hero--sodexo-site"
                btn={{
                    value: "En savoir plus",
                    action: "/projets/sodexo-site"
                }}
            />
        {/* </section> */}
        
       

        

       
        
        { /* <section className="scroll-section"> */}
            <ImageCover
                title="Livré !"
                content="Manger sain et équilibré au bureau ce n'est pas toujours évident et c'est important !"
                classDiv="hero--livre"
                btn={{ 
                    value: "En savoir plus",
                    action: "/projets/livre"
                }}
            />
        {/* </section> */}
       
        { /* <section className="scroll-section"> */}
            {/* <ImageCover
                title="Cosmos Catalogue"
                content="lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsum"
                classDiv="hero--cosmoscatalogue"
                btn={{ 
                    value: "En savoir plus",
                    action: "/projets/cosmos-catalogue"
                }}
            /> */}
        {/* </section> */}

        { /* <section className="scroll-section"> */}
            <ImageCover
                title="LA ROUE DE LA FORTUNE"
                content={ `
                Faites tourner la roue pour gagner : un jeu de hasard qui n’en est pas vraiment un ! La roue a été programmée pour faire gagner aux participants une remise de 5 % ou de 6 %. Le gagnant reçoit son bon de réduction directement sur sa boîte mail
                `}
                classDiv="hero--larouedelafortune"
                btn={{
                value: "En savoir plus",
                action: "/projets/cosmos"
                }}
            />
        {/* </section> */}

        { /* <section className="scroll-section"> */}
            <ImageCover
                title="TACOS DE LYON"
                content="Le sandwich parfait existe, et il est à portée de clics. Scrollez, sélectionnez, modifiez, validez... Laissez vos envies s’exprimer, vous voilà avec le tacos idéal ! Simple d’utilisation, ergonomique et intuitive, la plateforme permet à l’utilisateur de personnaliser ses sandwiches à l’infini."
                classDiv="hero--tacos"
                btn={{
                value: "En savoir plus",
                action: "/projets/tacos-de-lyon"
                }}
            />
        {/* </section> */}

        
    </React.Fragment>
)

export default LastRealisation

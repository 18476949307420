// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Sponsores from "./sponsores"
import WhoAreWe from "./who-are-we"
import ProjectCube from "./project-cube"

const RealisationSolution = () => (
	<div className="scroll-jacker__cassette pp-scrollable" style={{ backgroundColor: "rgba(35, 35, 35, 0.96)" }}>
        <ul className="gallery social-feed-list">

        <ProjectCube
            title="La Caisse enregistreuse sur iPad"
            action="/projets/precision-systems"
            image="/img/posts/precisionsystems.jpg"
        />
        <ProjectCube
            title="MY SODEXO MAROC"
            action="/projets/sodexo"
            image="/img/posts/sodexo.jpg"
        />
        < ProjectCube
            title = "My DHJ Official App"
            action = "/projets/dhj"
            image = "/img/posts/dhj.jpg"
        />
        <ProjectCube
            title="Minyadi"
            action="/projets/minyadi"
            image="/img/posts/minyadi.jpg"
        />
        < ProjectCube
            title = "SODEXO MAROC"
            action = "/projets/sodexo-site"
            image = "/img/posts/sodexo-site.jpg"
        />

        <ProjectCube
            title="Livré !"
            action="/projets/livre"
            image="/img/posts/livre.jpg"
        />

        {/* <ProjectCube
            title="Cosmos Catalogue"
            action="/projets/cosmos-catalogue"
            image="/img/posts/cosmos_catalog.jpg"
        /> */}
        

        

        

        

        

        <ProjectCube
            title="LA ROUE DE LA FORTUNE"
            action="/projets/cosmos"
            image="/img/posts/cosmos.jpg"
        />

        <ProjectCube
            title="PLATEFORME DE COMMANDE EN LIGNE"
            action="/projets/tacos-de-lyon"
            image="/img/posts/tacosdelyon.jpg"
        />

       

        
        

        </ul>

        <Sponsores />

        <WhoAreWe />

        <br />

    </div>
)

export default RealisationSolution
